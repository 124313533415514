import React, { useEffect, useState } from "react";
import { errorToast } from "../../helper/toast";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../library/Button";
import InputField from "../../library/TextField";
import "./style.css";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import { setAuthLocalStorage } from "../../storage/getLocalStorage";
import { useNavigate } from "react-router-dom";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { StorageConstant } from "../../constant/StorageConstant";
import useUserStore from "../../store/userStore";

const Login = () => {
  const { setUser } = useUserStore();
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });

  const [eye, setEye] = useState(true);

  useEffect(() => {
    async function getLoginHeader() {
      const loginHeader = localStorage.getItem(StorageConstant.LOGIN_HEADER);
      if (loginHeader) {
        navigate("/", { replace: true });
      }
    }
    getLoginHeader();
  }, [navigate]);

  const eyeIcon = () => {
    return eye ? <BsFillEyeFill /> : <BsFillEyeSlashFill />;
  };

  const inputType = () => {
    return eye ? "password" : "text";
  };

  const toHideShowPassword = () => {
    setEye(!eye);
  };

  const [error, setError] = useState({
    username: "",
    password: "",
  });

  const setUserName = (e) => {
    setLoginData({ ...loginData, username: e.target.value });
    setError({ ...error, username: "" });
    const { value } = e.target;
    setUser({ username: value });
  };

  const setPassword = (e) => {
    setLoginData({ ...loginData, password: e.target.value });
    setError({ ...error, password: "" });
    const { value } = e.target;
    setUser({ password: value });
  };

  const validate = () => {
    let result = true;
    if (!loginData.username) {
      setError({ ...error, username: "Invalid Username" });
      result = false;
    } else if (!loginData.password) {
      setError({ ...error, password: "Invalid Password" });
      result = false;
    } else {
      result = true;
    }
    return result;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      callAPI(
        APIUrl + NetworkConfiguration.LOGIN,
        "POST",
        JSON.stringify(loginData),
        { "Content-Type": "application/json" }
      )
        .then((res) => {
          console.log(res, "kjnawdjk");
          if (res?.token) {
            setAuthLocalStorage(res?.token);
            navigate("/");
          } else {
            errorToast("Invalid response from the server.");
          }
        })
        .catch((err) => {
          errorToast("Login failed. Please try again.");
          console.error(err);
        });
    }
  };

  return (
    <div className="page_container">
      <div className="login_container">
        <form onSubmit={onSubmit}>
          <h3 style={{ textAlign: "center" }}>Admin Login</h3>
          <p style={{ textAlign: "center" }}>Please login to your account</p>

          {/* Username input */}
          <InputField
            type="email"
            onChange={setUserName}
            labelStyle={{ color: "white" }}
            label={"Username"}
            error={error.username}
          />
          <br />

          {/* Password input */}
          <InputField
            label={"Password"}
            labelStyle={{ color: "white" }}
            onChange={setPassword}
            type={inputType()}
            error={error.password}
            icon={eyeIcon()}
            onEyeClick={toHideShowPassword}
          />
          <br />

          {/* Forgot Password link */}
          <p
            onClick={() => {
              navigate("/changepassword");
            }}
            className="password__style"
          >
            Forgot Password?
          </p>

          {/* Submit button */}
          <Button
            text="Login"
            style={{
              height: "7vh",
              width: "13vw",
            }}
          />
          <br />
        </form>
      </div>
    </div>
  );
};

export default Login;
