// export const APIUrl = "https://apikickr.kickrtech.in/api/v1/admin/";
export const APIUrl = "https://apikickr.kickrtechnology.in/api/v1/admin/";
// export const APIUrl = "https://apijoingroup-api.onrender.com/api";

export const NetworkConfiguration = {
  DASHBOARD: "matrix",
  MONTHLYSALES: "monthlySales",
  MONTHLYUSER: "monthlyUser",
  PRODUCTS: "getProducts",
  LOGIN: "login",
  ADDPRODUCTS: "addProduct",
  DELETEPRODUCT: "deleteProduct",
  UPDATEPRODUCT: "updateProduct",
  GETCATEGORIES: "getCategory",
  ADDCATEGORY: "addCategory",
  DELETECATEGORY: "deleteCategory",
  GETSUBCATEGORIES: "getSubCategory",
  DELETESUBCATEGORY: "deleteSubCategory",
  ADDSUBCATEGORY: "addSubCategory",
  SUBCATEGORYBYCATEGORY: "subCategoryByCat",
  TESTIMONIALS: "testimonials",
  TESTIMONIAL: "testimonial",
  JOBREQUEST: "jobrequest",
  CONTACTDETAILS: "contactUs/getAllContactUs",
  DELETECONTACT: "contactUs/contactUsDelete",
  GETORDERS: "order/all-orders",
  TEAMS: "teams",
  TEAM: "team",
  GETUSERS: "users",
  UPDATE_USER: "editUser",
  DELETE_USER: "deleteUser",
  GETCARTS: "getCarts",
  CLIENT: "client",
  OPENINGS: "openings",
  OPENING: "opening",
  TECHNOLOGIES: "technologies",
  TECHNOLOGY: "technology",
  MATRIX: "kickr-data",
  IMAGE: "upload",
  IMAGES: "upload-multi",
  EVENTS: "events",
  EVENT: "event",
  UPDATECATEGORY: "updateCategoryName",
  UPDATESUBCATEGORY: "updateSubCategory",
  BANNER: "banner",
  SENDNOTIFICATION: "sendNotification",
  SENDOTP: "sendMail",
  VERIFYOTP: "verifyMailOtp",
  EDITPASSWORD: "editProfile",
  CHANGEPASSWORD: "changePassword",
  SUSPENDUSER: "suspendUser",
  SUSPENDED: "suspended",
  WARNED: "warned",
  WARN_USER: "warnUser",
  UPDATE_ORDER_STATUS: "order/update-order",
  POLLS: "poll",
  ADDQUESTION: "addquestion",
  DOITBETTER: "doItBetter",
  GIVEAWAY:"giveaway",
  CODE:"code",
  GETGIVEAWAY:"giveaway",
  GETQA:"qa",
  GETPOLL:"poll",
  GETQUIZ:"quiz",
  GETDOITBETTER:"doItBetter",
  SIDEBAR:"sidebar"

};
