/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";
import "./style.css";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import { ToastContainer, toast } from "react-toastify";
import Button from "../../library/Button";
import { Navigate, useNavigate } from "react-router-dom";
import moment from "moment";

const Index = () => {
  const navigate = useNavigate();

  const [selectQuiz, setSelectQuiz] = useState("Q/A");
  const [select, setSelect] = useState("ADD Q/A");
  const [qaData, setQaData] = useState([]);
  const [pollsData, setPollsData] = useState([]);
  const [pollQuestion, setPollQuestion] = useState("");
  const [addQuestion, setAddQuestion] = useState("");
  const [pollOptions, setPollOptions] = useState(["", "", "", ""]);
  const [addOptions, setAddOption] = useState(["", "", "", ""]);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [description, setDescription] = useState();
  const [enterCode, setEnterCode] = useState();
  const [title, setTitle] = useState();
  const [descriptionGiveaway, setDescriptionGiveaway] = useState();
  const [topic, setTopic] = useState();
  const [language, setLanguage] = useState();
  const [giveaway, setGiveaway] = useState();
  const [doitbetter, setDoItBetter] = useState();

  const [questions, setQuestions] = useState([
    { question: "", options: ["", "", "", ""] },
  ]);

  const handleAddQuestionQuiz = () => {
    setQuestions([...questions, { question: "", options: ["", "", "", ""] }]);
  };

  const handlePollQuestionChange = (e) => {
    setPollQuestion(e.target.value);
  };

  const handlePollOptionChange = (e, index) => {
    const newOptions = [...pollOptions];
    newOptions[index] = e.target.value;
    setPollOptions(newOptions);
  };

  const createPollPayload = () => {
    const choices = pollOptions.map((option, index) => ({
      value: option,
    }));

    const payload = {
      question: pollQuestion,
      choices,
    };

    return payload;
  };

  const handleADDQuestionChange = (e) => {
    setAddQuestion(e.target.value);
  };

  const handleADDOptionChange = (e, index) => {
    const newOptions = [...addOptions];
    newOptions[index] = e.target.value;
    setAddOption(newOptions);
  };

  const createADDquestionPayload = () => {
    const options = addOptions.map((option, index) => ({
      text: option,
      isCorrect: index === selectedAnswerIndex,
    }));

    const payload = {
      question: addQuestion,
      options,
      // answer: selectedAnswerIndex, // Add the selected answer index
    };

    return payload;
  };
  console.log(createPollPayload());
  const handleAddPolls = () => {
    callAPI(
      APIUrl + NetworkConfiguration.POLLS,
      "POST",
      JSON.stringify(createPollPayload()),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        console.log(res.data);
        toast.success("Polls Added Successfully");
      })

      .catch((err) => {
        console.log(err);
      });
  };

  console.log(createADDquestionPayload());
  const handleAddQuestion = () => {
    callAPI(
      APIUrl + NetworkConfiguration.ADDQUESTION,
      "POST",
      JSON.stringify(createADDquestionPayload()),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        console.log(res.data);
        toast.success("Question Added Successfully");
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handledoitbetter = () => {
    callAPI(
      APIUrl + NetworkConfiguration.DOITBETTER,
      "POST",

      JSON.stringify({
        description: description,
        code: enterCode,
        topic: topic,
        language: language,
      }),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        console.log(res.data);
        toast.success("Do It Better Added Successfully");
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handleyourcode = (e) => {
    setEnterCode(e.target.value);
  };

  const handledescription = (e) => {
    setDescription(e.target.value);
  };

  const handletopic = (e) => {
    setTopic(e.target.value);
  };

  const handlelanguage = (e) => {
    setLanguage(e.target.value);
  };

  const handlegiveaway = () => {
    callAPI(
      APIUrl + NetworkConfiguration.GIVEAWAY,
      "POST",
      JSON.stringify({
        title: title,
        description: descriptionGiveaway,
      }),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        console.log(res.data);
        toast.success("Giveaway Added Successfully");
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handletitlegiveaway = (e) => {
    setTitle(e.target.value);
  };

  const handledescriptiongiveaway = (e) => {
    setDescriptionGiveaway(e.target.value);
  };

  // "list api integration" //
  // "list api integration" //

  const handleGiveawayList = () => {
    setSelect("GIVEAWAY LIST");
    callAPI(APIUrl + NetworkConfiguration.GETGIVEAWAY, "GET")
      .then((res) => {
        console.log(res.data);
        setGiveaway(res.data);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handleqa = () => {
    setSelect("Q/A LIST");
    callAPI(APIUrl + NetworkConfiguration.GETQA, "GET")
      .then((res) => {
        console.log(res.data);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handlepoll = () => {
    setSelect("POLLS LIST");
    callAPI(APIUrl + NetworkConfiguration.GETPOLL, "GET")
      .then((res) => {
        console.log(res.data);
        setPollsData(res.data);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handleQuiz = () => {
    setSelect("QUIZ LIST");
    callAPI(APIUrl + NetworkConfiguration.GETQUIZ, "GET")
      .then((res) => {
        console.log(res.data);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handledoitbetterlist = () => {
    setSelect("DOITBETTER LIST");
    callAPI(APIUrl + NetworkConfiguration.GETDOITBETTER, "GET")
      .then((res) => {
        console.log(res.data);
        setDoItBetter(res.data);
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const handlecontest = () => {
    callAPI(APIUrl + NetworkConfiguration.GETCONTEST, "GET")
      .then((res) => {
        console.log(res.data);
      })

      .catch((err) => {
        console.log(err);
      });
  };
  // console.log(giveaway);
  return (
    <div>
      <div className="path_styLE">
        <div>
          <h3 className="path__header">Code Mania</h3>
        </div>

        <div style={{ marginTop: "1rem" }}>
          <button
            onClick={() => navigate("/Component")}
            type="btnn"
            className="component_btn"
          >
            COMPONENT
          </button>
        </div>
      </div>

      <div className="codemaina_btns">
        <button
          onClick={() => setSelectQuiz("Q/A")}
          style={
            selectQuiz === "Q/A"
              ? {
                  backgroundColor: "#2e49ff",
                  color: "white",
                }
              : {}
          }
          type="button"
          className="qa_btn"
        >
          Q/A
        </button>
        <button
          onClick={() => setSelectQuiz("POLLS")}
          style={
            selectQuiz === "POLLS"
              ? {
                  backgroundColor: "#2e49ff",
                  color: "white",
                }
              : {}
          }
          type="button"
          className="qa_btn"
        >
          POLLS
        </button>
        <button
          onClick={() => setSelectQuiz("QUIZ")}
          style={
            selectQuiz === "QUIZ"
              ? {
                  backgroundColor: "#2e49ff",
                  color: "white",
                }
              : {}
          }
          type="button"
          className="qa_btn"
        >
          QUIZ
        </button>
        <button
          onClick={() => setSelectQuiz("GIVEAWAY")}
          style={
            selectQuiz === "GIVEAWAY"
              ? {
                  backgroundColor: "#2e49ff",
                  color: "white",
                }
              : {}
          }
          type="button"
          className="qa_btn"
        >
          GIVEAWAY
        </button>
        <button
          onClick={() => setSelectQuiz("CONTEST")}
          style={
            selectQuiz === "CONTEST"
              ? {
                  backgroundColor: "#2e49ff",
                  color: "white",
                }
              : {}
          }
          type="button"
          className="qa_btn"
        >
          CONTEST
        </button>
        <button
          onClick={() => setSelectQuiz("DO IT BETTER")}
          style={
            selectQuiz === "DO IT BETTER"
              ? {
                  backgroundColor: "#2e49ff",
                  color: "white",
                }
              : {}
          }
          type="button"
          className="qa_btn"
        >
          DO IT BETTER
        </button>
      </div>

      {selectQuiz === "Q/A" && (
        <>
          <div
            style={{
              marginTop: "2rem",
              marginLeft: "2rem",
              display: "flex",
              gap: "20px",
            }}
          >
            <button
              onClick={() => setSelect("ADD Q/A")}
              style={
                select === "ADD Q/A"
                  ? { backgroundColor: "#2e49ff", color: "white" }
                  : {}
              }
              type="button"
              className="list_btns"
            >
              ADD Q/A
            </button>
            <button
              onClick={handleqa}
              style={
                select === "Q/A LIST"
                  ? { backgroundColor: "#2e49ff", color: "white" }
                  : {}
              }
              type="button"
              className="list_btns"
            >
              Q/A LIST
            </button>
          </div>

          {select === "ADD Q/A" ? (
            <>
              <div>
                <div className="q_a_inputs">
                  <label>Questions</label>
                  <input
                    value={addQuestion}
                    onChange={handleADDQuestionChange}
                    type="text"
                    placeholder=""
                  ></input>
                </div>
              </div>
              <div className="polls_input">
                <label style={{ marginLeft: "2rem" }}>Options</label>
                <div className="all_inputs">
                  {addOptions.map((option, index) => (
                    <div key={index} className="option-input">
                      <input
                        type="radio"
                        name="answer"
                        value={index}
                        checked={selectedAnswerIndex === index}
                        onChange={() => setSelectedAnswerIndex(index)}
                        className="radio-btn"
                      />
                      <input
                        value={addOptions[index]}
                        onChange={(e) => handleADDOptionChange(e, index)}
                        type="text"
                        name="poll"
                        placeholder={`Option ${index + 1}`}
                        className="option-input-field"
                      />
                    </div>
                  ))}
                </div>
                <div>
                  <button
                    onClick={handleAddQuestion}
                    type="button"
                    className="code_mania_submit_btn"
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </>
          ) : select === "Q/A LIST" ? (
            <div>
              <table className="host_table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>First Name</th>
                    <th>Email</th>
                    <th>User ID</th>
                    <th>DOB</th>
                    <th>Mobile Number</th>
                  </tr>
                </thead>
                <tbody>
                  {qaData && qaData.length > 0 ? (
                    qaData.map((rowData, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{rowData?.userId?.firstName}</td>
                        <td>{rowData?.userId?.email}</td>
                        <td>{rowData?.userId?.userId}</td>
                        <td>{rowData?.dob}</td>
                        <td>{rowData?.mobileNumber}</td>
                      </tr>
                    ))
                  ) : (
                    <tr style={{ textAlign: "center" }}>
                      <td colSpan="6">NO DATA FOUND</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ) : null}
        </>
      )}

      {selectQuiz === "POLLS" && (
        <>
          <div
            style={{
              marginTop: "2rem",
              marginLeft: "2rem",
              display: "flex",
              gap: "20px",
            }}
          >
            <button
              onClick={() => setSelect("ADD POLLS")}
              style={
                select === "ADD POLLS"
                  ? { backgroundColor: "#2e49ff", color: "white" }
                  : {}
              }
              type="button"
              className="list_btns"
            >
              ADD POLLS
            </button>
            <button
              onClick={handlepoll}
              style={
                select === "POLLS LIST"
                  ? { backgroundColor: "#2e49ff", color: "white" }
                  : {}
              }
              type="button"
              className="list_btns"
            >
              POLLS LIST
            </button>
          </div>
          {select === "ADD POLLS" ? (
            <>
              <div className="q_a_inputs">
                <label>Poll Question</label>
                <input
                  type="text"
                  placeholder=""
                  value={pollQuestion}
                  onChange={handlePollQuestionChange}
                />
              </div>
              <div className="polls_input">
                <label style={{ marginLeft: "2rem" }}>Options</label>
                <div className="all_inputs">
                  <input
                    type="text"
                    name="poll"
                    placeholder="option 1"
                    value={pollOptions[0]}
                    onChange={(e) => handlePollOptionChange(e, 0)}
                  />
                  <input
                    type="text"
                    name="poll"
                    placeholder="option 2"
                    value={pollOptions[1]}
                    onChange={(e) => handlePollOptionChange(e, 1)}
                  />
                </div>
                <div className="all_inputs">
                  <input
                    type="text"
                    name="poll"
                    placeholder="option 3"
                    value={pollOptions[2]}
                    onChange={(e) => handlePollOptionChange(e, 2)}
                  />
                  <input
                    type="text"
                    name="poll"
                    placeholder="option 4"
                    value={pollOptions[3]}
                    onChange={(e) => handlePollOptionChange(e, 3)}
                  />
                </div>
                <button
                  onClick={handleAddPolls}
                  type="button"
                  className="code_mania_submit_btn"
                >
                  SUBMIT
                </button>
              </div>
              <div></div>
            </>
          ) : select === "POLLS LIST" ? (
            <div>
              <table className="host_table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Poll Question</th>
                    <th>Options</th>
                    <th>Votes</th>
                  </tr>
                </thead>
                <tbody>
                  {pollsData && pollsData?.length > 0 ? (
                    pollsData?.map((pollData, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{pollData?.question}</td>
                        <td>
                          <ul>
                            {pollData?.options?.map((option, optionIndex) => (
                              <li key={optionIndex}>{option}</li>
                            ))}
                          </ul>
                        </td>
                        <td>
                          <ul>
                            {pollData?.votes?.map((vote, voteIndex) => (
                              <li key={voteIndex}>{vote}</li>
                            ))}
                          </ul>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr style={{ textAlign: "center" }}>
                      <td colSpan="4">NO DATA FOUND</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ) : null}
        </>
      )}
      {selectQuiz === "QUIZ" && (
        <>
          <div
            style={{
              marginTop: "2rem",
              marginLeft: "2rem",
              display: "flex",
              gap: "20px",
            }}
          >
            <button
              onClick={() => setSelect("ADD QUIZ")}
              style={
                select === "ADD QUIZ"
                  ? { backgroundColor: "#2e49ff", color: "white" }
                  : {}
              }
              type="button"
              className="list_btns"
            >
              ADD QUIZ
            </button>
            <button
              onClick={handleQuiz}
              style={
                select === "QUIZ LIST"
                  ? { backgroundColor: "#2e49ff", color: "white" }
                  : {}
              }
              type="button"
              className="list_btns"
            >
              QUIZ LIST
            </button>
          </div>
          {select === "ADD QUIZ" ? (
            <>
              {questions.map((q, index) => (
                <div key={index} className="q_a_inputs">
                  <label>Title</label>
                  <input type="text" placeholder="" />

                  <label style={{ marginTop: "10px" }}>Description</label>
                  <input type="text" placeholder="" />

                  <label style={{ marginTop: "10px" }}>Upload</label>
                  <input
                    style={{ border: "1px solid black" }}
                    type="file"
                    id="avatar"
                    name="avatar"
                    accept="image/png, image/jpeg"
                  />

                  <label style={{ marginTop: "10px" }}>
                    QUIZ Question {index + 1}
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    value={q.question}
                    onChange={(e) => {
                      const newQuestions = [...questions];
                      newQuestions[index].question = e.target.value;
                      setQuestions(newQuestions);
                    }}
                  />
                  <div className="polls_input">
                    <label>Options</label>
                    <div className="all_inputs_Quiz">
                      {q.options.map((option, optionIndex) => (
                        <input
                          key={optionIndex}
                          type="text"
                          name="poll"
                          placeholder={`Option ${optionIndex + 1}`}
                          value={option[index]}
                          onChange={(e) => {
                            const newQuestions = [...questions];
                            newQuestions[index].options[optionIndex] =
                              e.target.value;
                            setQuestions(newQuestions);
                          }}
                        />
                      ))}
                      <div style={{ marginLeft: "2rem", marginTop: "1rem" }}>
                        <button type="button" onClick={handleAddQuestionQuiz}>
                          <span className="plus_icon">+</span> Add Question
                        </button>
                      </div>
                      <div>
                        <button type="button" className="code_mania_submit_btn">
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : select === "QUIZ LIST" ? (
            <div>
              <table className="host_table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>QUIZ Question</th>
                    <th>Options</th>
                    <th>Votes</th>
                  </tr>
                </thead>
                <tbody>
                  {pollsData && pollsData.length > 0 ? (
                    pollsData.map((pollData, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{pollData.question}</td>
                        <td>
                          <ul>
                            {pollData.options.map((option, optionIndex) => (
                              <li key={optionIndex}>{option}</li>
                            ))}
                          </ul>
                        </td>
                        <td>
                          <ul>
                            {pollData.votes.map((vote, voteIndex) => (
                              <li key={voteIndex}>{vote}</li>
                            ))}
                          </ul>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr style={{ textAlign: "center" }}>
                      <td colSpan="4">NO DATA FOUND</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ) : null}
        </>
      )}
      {selectQuiz === "GIVEAWAY" && (
        <>
          <div
            style={{
              marginTop: "2rem",
              marginLeft: "2rem",
              display: "flex",
              gap: "20px",
            }}
          >
            <button
              onClick={() => setSelect("ADD GIVEAWAY")}
              style={
                select === "ADD GIVEAWAY"
                  ? { backgroundColor: "#2e49ff", color: "white" }
                  : {}
              }
              type="button"
              className="list_btns"
            >
              ADD GIVEAWAY
            </button>
            <button
              onClick={handleGiveawayList}
              style={
                select === "GIVEAWAY LIST"
                  ? { backgroundColor: "#2e49ff", color: "white" }
                  : {}
              }
              type="button"
              className="list_btns"
            >
              GIVEAWAY LIST
            </button>
          </div>
          {select === "ADD GIVEAWAY" ? (
            <>
              <div className="q_a_inputs">
                <label>Title</label>
                <input
                  value={title}
                  onChange={handletitlegiveaway}
                  type="text"
                  placeholder=""
                />
              </div>
              <div className="q_a_inputs">
                <label>Description</label>
                <input
                  value={descriptionGiveaway}
                  onChange={handledescriptiongiveaway}
                  type="text"
                  placeholder=""
                />
              </div>
              <div className="q_a_inputs">
                <label>Upload</label>
                <input
                  style={{ border: "1px solid black" }}
                  type="file"
                  id="avatar"
                  name="avatar"
                  accept="image/png, image/jpeg"
                />
              </div>

              <button
                onClick={handlegiveaway}
                type="button"
                className="code_mania_submit_btn"
              >
                SUBMIT
              </button>
              <div></div>
            </>
          ) : select === "GIVEAWAY LIST" ? (
            <div>
              <table className="host_table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Image</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {giveaway && giveaway.length > 0 ? (
                    giveaway.map((pollData, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{pollData.imgUrls}</td>
                        <td>{pollData?.title}</td>
                        <td>{pollData?.description}</td>
                        <td>
                          {moment(pollData?.createdAt).format("DD-MM-YYYY, LT")}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr style={{ textAlign: "center" }}>
                      <td colSpan="5">NO DATA FOUND</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ) : null}
        </>
      )}

      {/* {selectQuiz === "CONTEST" && (
        <>
          <div
            style={{
              marginTop: "2rem",
              marginLeft: "2rem",
              display: "flex",
              gap: "20px",
            }}
          >
            <button
              onClick={() => setSelect("CONTEST")}
              style={
                select === "CONTEST"
                  ? { backgroundColor: "#2e49ff", color: "white" }
                  : {}
              }
              type="button"
              className="list_btns"
            >
              ADD CONTEST
            </button>
            <button
              onClick={handledoitbetterlist}
              style={
                select === "CONTEST LIST"
                  ? { backgroundColor: "#2e49ff", color: "white" }
                  : {}
              }
              type="button"
              className="list_btns"
            >
              CONTEST LIST
            </button>
          </div>
        </>

         */}

      {selectQuiz === "DO IT BETTER" && (
        <>
          <div
            style={{
              marginTop: "2rem",
              marginLeft: "2rem",
              display: "flex",
              gap: "20px",
            }}
          >
            <button
              onClick={() => setSelect("ADD DOITBETTER")}
              style={
                select === "ADD DOITBETTER"
                  ? { backgroundColor: "#2e49ff", color: "white" }
                  : {}
              }
              type="button"
              className="list_btns"
            >
              ADD DO IT BETTER
            </button>
            <button
              onClick={handledoitbetterlist}
              style={
                select === "DOITBETTER LIST"
                  ? { backgroundColor: "#2e49ff", color: "white" }
                  : {}
              }
              type="button"
              className="list_btns"
            >
              DO IT BETTER LIST
            </button>
          </div>
          {select === "ADD DOITBETTER" ? (
            <>
              <div className="doitbeteer_description">
                <label for="cars">Choose a Language:</label>

                <select
                  onChange={handlelanguage}
                  value={language}
                  style={{ width: "25vw", height: "5vh" }}
                  name="Select"
                  id="Slect"
                >
                  <option value="Select">Select</option>
                  <option value="Reactjs">Reactjs</option>
                  <option value="Nextjs">Nextjs</option>
                  <option value="Nodejs">Nodejs</option>
                  <option value="Javascript">Javascript</option>
                  <option value="Html">Html</option>
                </select>
              </div>
              <div className="doitbeteer_description">
                <label>Topic</label>
                <input
                  value={topic}
                  onChange={handletopic}
                  type="text"
                  placeholder=""
                />
              </div>
              <div className="doitbeteer_description">
                <label>Description</label>
                <input
                  value={description}
                  onChange={handledescription}
                  type="text"
                  placeholder=""
                />
              </div>

              <div className="doitbeteer_inputs">
                <label>Paste Your Code Here</label>
                <input
                  value={enterCode}
                  onChange={handleyourcode}
                  type="text"
                  placeholder=""
                />
              </div>

              <button
                onClick={handledoitbetter}
                type="button"
                className="code_mania_submit_btn"
              >
                SUBMIT
              </button>
              <div></div>
            </>
          ) : select === "DOITBETTER LIST" ? (
            <div>
              <table className="host_table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Topic</th>
                    <th>Language</th>
                    <th>Description</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {doitbetter && doitbetter.length > 0 ? (
                    doitbetter.map((pollData, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{pollData.topic}</td>
                        <td>{pollData.language}</td>
                        <td>{pollData.description}</td>
                        <td>
                          {moment(pollData.createdAt).format("DD-MM-YYYY, LT")}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr style={{ textAlign: "center" }}>
                      <td colSpan="5">NO DATA FOUND</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ) : null}
        </>
      )}

      <ToastContainer />
    </div>
  );
};

export default Index;
