"use client";
import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import { ImageService } from "../../service/ImageService";
import { toast } from "react-toastify";
import ReactDOM from "react-dom";
import * as Babel from "@babel/standalone";

const Index = () => {
  const [code, setCode] = useState();
  const [codetype, setCodeType] = useState();
  const [image, setImage] = useState();
  const [category, setCategory] = useState();
  const [type, setType] = useState();

  const handleCode = () => {
    callAPI(
      APIUrl + NetworkConfiguration.CODE,
      "POST",
      JSON.stringify({
        code: code,
        category: category,
        type: type,
      }),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        console.log(res);
        ImageService.imageUpload(image, "code", res?.data?._id).then(() => {
          // handleCode();
          console.log(res);
        });
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handleCodeinput = (e) => {
    setCode(e.target.value);
  };

  const handleCodetype = (e) => {
    setCodeType(e.target.value);
  };

  const handleimage = (e) => {
    setImage(e.target.files[0]);
  };

  const handlecategory = (e) => {
    setCategory(e.target.value);
  };

  const handletype = () => {
    callAPI(APIUrl + NetworkConfiguration.SIDEBAR, "GET")
      .then((res) => {
        console.log(res.data);
        setType(res.data);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handleaddcategory = () => {
    callAPI(
      APIUrl + NetworkConfiguration.SIDEBAR,
      "POST",
      JSON.stringify({
        name: category,
      }),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        console.log(res.data);
        setCategory("");
        toast.success("Category Added Succesfully");
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const iframeRef = useRef(null);
  useEffect(() => {
    console.log("hello");
    const renderPreview = () => {
      const iframeDocument = iframeRef.current.contentDocument;

      // Prepare the iframe document with React and ReactDOM
      const iframeHead = iframeDocument.head;
      const reactScript = iframeDocument.createElement("script");
      reactScript.src = "https://unpkg.com/react/umd/react.development.js";
      const reactDomScript = iframeDocument.createElement("script");
      reactDomScript.src =
        "https://unpkg.com/react-dom/umd/react-dom.development.js";
      const babelScript = iframeDocument.createElement("script");
      babelScript.src = "https://unpkg.com/@babel/standalone/babel.min.js";

      iframeHead.appendChild(reactScript);
      iframeHead.appendChild(reactDomScript);
      iframeHead.appendChild(babelScript);

      babelScript.onload = () => {
        const compiledCode = Babel.transform(code, {
          presets: ["react", "es2015"],
        }).code;

        const script = iframeDocument.createElement("script");
        script.type = "text/babel";
        script.innerHTML = `
          ${compiledCode}
          const root = document.getElementById('root');
          ReactDOM.render(React.createElement(Index), root);
        `;
        console.log(script);
        iframeDocument.open();
        iframeDocument.write('<div id="root"></div>');
        iframeDocument.body.appendChild(script);
        iframeDocument.close();
      };
    };

    const handleIframeLoad = () => {
      renderPreview();
    };

    const iframeElement = iframeRef.current;
    iframeElement.addEventListener("load", handleIframeLoad);
    console.log(iframeElement);

    return () => {
      iframeElement.removeEventListener("load", handleIframeLoad);
    };
  }, [code]);
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>
        <div className="path_styLE">
          <div>
            <h3 className="path__header">Component</h3>
          </div>
        </div>

        <div className="input">
          <label>Code</label>
          {/* <input
            onChange={handleCodeinput}
            value={code}
            type="text"
            placeholder=""
          ></input> */}
          <textarea
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter your React code here..."
          />
        </div>

        <div className="input1">
          <label>Category</label>
          <div className="input-wrapper">
            <input
              onChange={handlecategory}
              value={category}
              type="text"
              placeholder=""
            />
            <button onClick={handleaddcategory}>Add Category</button>
          </div>
        </div>

        <div className="input2">
          <label>Image</label>
          <input
            onChange={handleimage}
            style={{ border: "1px solid black" }}
            type="file"
            id="image"
            alt="Login"
          />
        </div>

        <div className="input3">
          <label>Type</label>
          <select
            onClick={handletype}
            style={{
              width: "20vw",
              height: "5vh",
            }}
            onChange={handleCodetype}
            value={codetype}
          >
            <option value="">Select a type</option>
            {type && type.length > 0
              ? type.map((rowdata, index) => (
                  <option key={index} value={rowdata.id}>
                    {rowdata.name}
                  </option>
                ))
              : null}
          </select>
        </div>

        <div>
          <button onClick={handleCode} type="btnn" className="submit_butn">
            SUBMIT
          </button>
        </div>
      </div>
      <iframe
        ref={iframeRef}
        title="Preview"
        sandbox="allow-scripts"
        style={{ width: "100%", height: "300px" }}
      />
    </div>
  );
};

export default Index;
